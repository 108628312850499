<template>
    <!-- 共读课程资源组件 -->
    <div>
        <el-form-item :label="`共读课程资源${index+2}：`" prop="title" label-width="180px">
            <el-input v-model.trim="title" maxlength="30" show-word-limit style="width:350px" @change="changeTitle"
                placeholder="请输入共读资源圈标题">
            </el-input>
        </el-form-item>
        <el-form-item prop="courseIds" style="display:inline-block" label-width="180px">
            <el-select v-model="courseIds" placeholder="请选择课程" multiple @change="changeCourse">
                <el-option v-for="item in courseList" :key="item.value" :label="item.name" :value="item.id">
                </el-option>
            </el-select>
        </el-form-item>
    </div>
</template>

<script>
    import {
        mapState
    } from "vuex";
    export default {
        props: {
            index: {
                type: Number
            },
            count: {
                type: Number
            },
            info: {
                type: Object,
                default: () => {
                    return {}
                }
            }
        },
        data() {
            return {
                courseIds: [], //选中的课程id
                courseList: [], //课程列表
                title: '',
            }
        },
        computed: {
            ...mapState({
                currentProject: state => state.user.currentProject,
            })
        },
        methods: {
            // 获取大赛类活动绑定课程列表
            async getListForActivityCreate() {
                let params = {
                    projectId: this.currentProject.id
                }
                let resData = await this.$Api.Activity.getListForActivityCreate(params)
                console.log('课程列表', resData.data);
                this.courseList = resData.data
            },
            // 改变标题
            changeTitle() {
                let courses = [];
                if (this.courseIds.length > 0) {
                    this.courseIds.map(item => {
                        courses.push({
                            id: item
                        })
                    })
                }
                this.$emit('change', {
                    data: {
                        title: this.title,
                        courses: courses
                    },
                    index: this.count
                })
            },
            // 改变选中课程
            changeCourse(val) {
                console.log('选中的课程id', val);
                let courses = [];
                if (val.length > 0) {
                    val.map(item => {
                        courses.push({
                            id: item
                        })
                    })
                }
                let datas = {
                    title: this.title,
                    courses: courses
                }
                this.$emit('change', {
                    data: datas,
                    index: this.count
                })
            },
        },
        mounted() {
            this.getListForActivityCreate();
            console.log('info', this.count, this.info)
            if (JSON.stringify(this.info) != '{}') {
                this.title = this.info.title;
                this.info.courses.map(item => {
                    this.courseIds.push(item.id);
                })
            }
        },
    }
</script>

<style>

</style>