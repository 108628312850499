<template>
    <div class="upload-file" @file-change="handleFileChange">
        <!-- 上传按钮 -->
        <el-col :span="8">
            <el-button class="upload" icon="el-icon-plus" @click="openDrawer(true)" :disabled="isDisable">
                {{text}}
            </el-button>
        </el-col>
        <!-- 提示和视频网址 -->
        <el-col :span="15">
            <div class="tips">
                <img src="@/assets/images/addclass/tishi.png" alt v-if="tips.image" />
                <div v-if="tips.url">
                    <p class="text">或粘贴视频网址：</p>
                    <el-input style="width:242px" show-word-limit v-model="link" @change="changeLink">
                    </el-input>
                </div>
            </div>
        </el-col>
        <!-- 展示列表 -->
        <ul class="file-list">
            <li class="csp" v-for="(item, index) in fileList" :key="index">
                <span class="name" @click="openFile(item)">{{ item.name }}</span>
                <button class="delete el-icon-close csp" :disabled="isDisable"
                    @click.prevent="deleteFile(index)"></button>
            </li>
        </ul>
        <!-- 本地断点续传 -->
        <el-drawer v-if="!BreakPointUpload" title="" :visible.sync="isOpenDrawer" @opened="openDialogAuto"
            :before-close="handleClose">
            <upimg ref="upimg" v-if="isOpenDrawer" :upimgload="true" @closeMain="closeMain"></upimg>
        </el-drawer>

        <!-- 右侧上传栏 -->
        <el-drawer v-if="BreakPointUpload" title="" :visible.sync="isOpenDrawer" :before-close="handleClose"
            :append-to-body="true" @opened="openDialogAuto">
            <ossUploader ref="upload" accept='.pdf' v-if="isOpenDrawer" @closeMain="closeMain"></ossUploader>
        </el-drawer>
    </div>
</template>

<script>
    export default {
        props: {
            // 名称
            name: {
                required: true,
                type: String
            },
            text: {
                type: String,
                default: '上传文件'
            },
            list: {
                type: Array
            },
            // 是否显示提示框和视频网址input
            tips: {
                type: Object,
                default: () => {
                    return {
                        image: false,
                        url: false,
                    }
                }
            },
            xlink: {
                type: String
            },
            isDisabled: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                isDisable: false,
                // upimgload: false,
                isOpenDrawer: false, // 侧边栏是否呈开启状态
                fileList: [], //文件列表
                fileMD5List: [], //文件md5列表
                flag: true, //是否是第一次进来
                link: ""
            }
        },
        watch: {
            isDisabled(newVal, oldVal) {
                if (newVal) {
                    this.isDisable = newVal
                }
            },
            xlink(newVal, oldVal) {
                if (newVal) {
                    this.link = this.xlink;
                }
            },
            list: {
                handler(newVal, oldVal) {
                    // console.log('newVal', newVal)
                    // console.log('oldVal',oldVal)
                    if (!newVal || JSON.stringify(newVal) == '[]') {
                        this.fileList = []
                        this.fileMD5List = []
                        this.link = ""
                        this.flag = true
                        this.handleFileChange();
                        return
                    }
                    if (JSON.stringify(newVal) != '[]' && this.flag) {
                        this.flag = false
                        this.fileList = this.list
                        this.fileList.map(item => {
                            item.name = item.filename;
                            item.filemd5 = this.downloadURL + item.identifier;
                            this.fileMD5List.push(item.identifier)
                        });
                        this.handleFileChange();
                    }

                },
                immediate: true,
            }
        },
        computed: {},
        methods: {
            // 关闭右侧上传抽屉提示弹窗
            handleClose(done) {
                this.$confirm('确认关闭？')
                    .then(_ => {
                        done();
                    })
                    .catch(_ => {});
            },
            // 删除文件
            deleteFile(index) {
                this.fileList.splice(index, 1);
                this.fileMD5List.splice(index, 1);
                this.$emit('uploadFile', {
                    name: this.name,
                    fileList: this.fileList,
                    fileMD5List: this.fileMD5List,
                    link: this.link
                });
            },
            // 打开右侧上传栏
            openDrawer(flag) {
                this.isOpenDrawer = flag;
                // console.log(this.myBrowser())
                // this.upimgload = this.myBrowser() == 'Chrome' ? false : true;
            },
            // 自动打开浏览器弹窗
            openDialogAuto() {
                this.$refs.upload.$el.querySelector("input").click();
            },
            // 文件上传成功后的回调
            closeMain(val) {
                // console.log(val);
                // this.fileMD5List.push(val.filemd5);
                this.$set(this.fileMD5List, 0, val.filemd5)
                // console.log(this.fileMD5List);
                let file = {
                    filemd5: this.downloadURL + val.filemd5,
                    name: val.name
                };
                // console.log(file)
                // this.fileList.push(file);
                this.$set(this.fileList, 0, file)
                this.handleFileChange();
            },
            changeLink(val) {
                this.handleFileChange();
            },
            // 文件预览及下载
            openFile(item) {
                window.open(item.filemd5)
            },
            handleFileChange() {
                this.$emit('uploadFile', {
                    name: this.name,
                    fileList: this.fileList,
                    fileMD5List: this.fileMD5List,
                    link: this.link
                });
            }
        },
        activated() {
            this.isDisable = this.isDisabled;
            this.link = this.xlink;
        },
        deactivated() {
            this.fileList = []
            this.fileMD5List = []
            this.link = ""
            this.isDisable = false
            this.flag = true
        },
    }
</script>

<style lang="less" scoped>
    .upload-file {
        /deep/ .el-button.upload {
            padding: 10px 20px;
        }


        .tips {
            margin-top: 3px;
            height: 40px;

            p.text {
                display: inline-block;
            }
        }

        .file-list {
            li {
                display: inline-block;
                line-height: 32px;

                margin: 13px 20px 5px 0;
                border-radius: 4px;
                padding: 0px 12px;

                color: #666;
                border: 1px solid rgba(204, 204, 204, .22);

                // background: rgba(204, 204, 204, 0.22);

                span.name {
                    display: inline-block;
                    line-height: 32px;
                    min-width: 60px;
                }

                .delete {
                    // background-image: url("~@/assets/images/course/closefile.png");
                    display: inline-block;

                    margin-left: 25px;
                    width: 10px;
                    height: 10px;



                    background-repeat: no-repeat;
                    background-size: 100% 100%;
                    vertical-align: -1px;
                }
            }
        }
    }
</style>